import React, { Component } from 'react';
import classNames from 'classnames';
import parse from 'html-react-parser';

class PropertyTooltip extends Component {
  constructor(props) {
    super(props);
    
    this.inputRef = React.createRef(null);
    
    this.state = {
      isVisible: false,
      readMore: false
    };
  }
  
  componentDidMount() {
    const { definition, truncate_length } = this.props
    
    console.log(definition.length >= truncate_length)
    
    if(definition.length >= truncate_length) {
      this.setState({ readMore: true })
    }
  }
  
  toggleTooltip = () => {
    const contentWrapperCoordinates = document.querySelector('.content-wrapper').getBoundingClientRect();
    const coordinates = this.inputRef.current.getBoundingClientRect();
    let styles = {}

    if (contentWrapperCoordinates.right < 340) {
      styles['left'] = `-${coordinates.left}px`
      styles['width'] = `${contentWrapperCoordinates.right}px`
    }
    
    if ((contentWrapperCoordinates.right - coordinates.right) < 330) {
      styles['right'] = `-${contentWrapperCoordinates.right - coordinates.right - 30}px`
    }
    
    this.setState({ isVisible: !this.state.isVisible, styles: styles })
  }
  
  readMore = () => {
    this.setState({ readMore: false })
  }
  
  render() {
    const { name, preview, definition } = this.props;
    const { isVisible, readMore, styles } = this.state;
    
    const tooltipButtonClasses = classNames('property-tooltip__button',
      isVisible ? 'opened' : 'closed')
    const tooltipContentClasses = classNames('property-tooltip__container',
      isVisible ? '' : 'hidden')
    const overlayClasses = classNames(isVisible ? 'property-tooltip__overlay' : '')
    
    return (
      <div className='property-tooltip' ref={this.inputRef}>
        <button className={tooltipButtonClasses} onClick={this.toggleTooltip} />
        <div className={overlayClasses} onClick={this.toggleTooltip}></div>
        <div className={tooltipContentClasses} style={styles}>
          <div className="property-tooltip__close-wrapper">
            <button className="property-tooltip__close"
                    onClick={this.toggleTooltip}/>
          </div>
          <div className="property-tooltip__header">{name}</div>
          <div className="property-tooltip__content">
            {readMore === true &&
              <div className="property-tooltip__preview">
                {parse(preview)}
                <div
                  className="property-tooltip__read-more"
                  onClick={this.readMore}
                >
                  Подробнее
                </div>
              </div>
            }
            {readMore === false &&
              <div>{parse(definition)}</div>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default PropertyTooltip;

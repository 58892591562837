window.switchProductVariant = function (event) {
  event.preventDefault();
  const dataId = this.dataset.switcher;
  const switchers = this.closest('.js-product-preview').querySelectorAll('.js-variant-switcher');
  const variants = this.closest('.js-product-preview').querySelectorAll('.product')
  const activeProductPreview = this.closest('.js-product-preview').querySelector(`[data-variant="${dataId}"]`);
  const activeSwitchers = this.closest('.js-product-preview').querySelectorAll(`[data-switcher="${dataId}"]`);

  for (let a = 0; a < switchers.length; a++) {
    switchers[a].classList.remove('checked');
  }

  for (let a = 0; a < variants.length; a++) {
    variants[a].classList.remove('active');
  }
  
  for (let a = 0; a < activeSwitchers.length; a++) {
    activeSwitchers[a].classList.add('checked');
  }
  event.target.classList.add('checked')
  activeProductPreview.classList.add('active')
}

window.openProductVariantsSelect = function (event) {
  event.preventDefault();
  
  this.querySelector('.js-product-variants-options').classList.toggle('open')
}

addMultiEventListenerByClass('DOMContentLoaded ajax:success jsRendered',
  '.js-variant-switcher', 'click', switchProductVariant)

addMultiEventListenerByClass('DOMContentLoaded ajax:success jsRendered',
  '.js-product-variants-select', 'click', openProductVariantsSelect)

window.addEventListener("popstate", function(e) {
  Rails.ajax({
    type: 'GET',
    url: location.pathname,
    success: function (data) {
      let product = document.querySelector('.js-product-wrapper');
      document.title = data.querySelector('title').textContent;
      document.head.querySelector('meta[name="description"]').setAttribute(
        "content", data.querySelector('meta[name="description"]').content);
      product.innerHTML = data.querySelector('.js-product-wrapper').innerHTML;
      fireJsRendered();
    }
  })
});
